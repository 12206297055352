.navbar {
    background: transparent;
}

@font-face {
    font-family: 'Montserrat-Light';
    src: url("./fonts/Montserrat-Light.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Regular';
    src: url("./fonts/Montserrat-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-SemiBold';
    src: url("./fonts/Montserrat-SemiBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat-Bold';
    src: url("./fonts/Montserrat-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


/*@mixin placeholder {
::-webkit-input-placeholder {@content}
:-moz-placeholder           {@content}
::-moz-placeholder          {@content}
:-ms-input-placeholder      {@content}  
}
*/


/* ==== Plcaeholder code ============= */


/* ================= Animation code ============= */

@-webkit-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@-moz-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@-ms-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@-o-keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@keyframes slide-down {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    100% {
        opacity: 0.9;
        transform: translateY(0);
    }
}

@-webkit-keyframes luxuryTopVer {
    0% {
        transform: translateY(100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(-100%);
    }
}

@-moz-keyframes luxuryTopVer {
    0% {
        transform: translateY(100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(-100%);
    }
}

@-ms-keyframes luxuryTopVer {
    0% {
        transform: translateY(100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(-100%);
    }
}

@-o-keyframes luxuryTopVer {
    0% {
        transform: translateY(100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(-100%);
    }
}

@keyframes luxuryTopVer {
    0% {
        transform: translateY(100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(-100%);
    }
}

@-webkit-keyframes luxuryTopHor {

    0%,
    25% {
        transform: translateX(-100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@-moz-keyframes luxuryTopHor {

    0%,
    25% {
        transform: translateX(-100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@-ms-keyframes luxuryTopHor {

    0%,
    25% {
        transform: translateX(-100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@-o-keyframes luxuryTopHor {

    0%,
    25% {
        transform: translateX(-100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@keyframes luxuryTopHor {

    0%,
    25% {
        transform: translateX(-100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes luxuryBotVer {
    0% {
        transform: translateY(-100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(100%);
    }
}

@-moz-keyframes luxuryBotVer {
    0% {
        transform: translateY(-100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(100%);
    }
}

@-ms-keyframes luxuryBotVer {
    0% {
        transform: translateY(-100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(100%);
    }
}

@-o-keyframes luxuryBotVer {
    0% {
        transform: translateY(-100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(100%);
    }
}

@keyframes luxuryBotVer {
    0% {
        transform: translateY(-100%);
    }

    25%,
    50% {
        transform: translateY(0);
    }

    75%,
    100% {
        transform: translateY(100%);
    }
}

@-webkit-keyframes luxuryBotHorr {

    0%,
    25% {
        transform: translateX(100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-moz-keyframes luxuryBotHorr {

    0%,
    25% {
        transform: translateX(100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-ms-keyframes luxuryBotHorr {

    0%,
    25% {
        transform: translateX(100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-o-keyframes luxuryBotHorr {

    0%,
    25% {
        transform: translateX(100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes luxuryBotHorr {

    0%,
    25% {
        transform: translateX(100%);
    }

    50%,
    75% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

@-webkit-keyframes arrobounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-moz-keyframes arrobounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-ms-keyframes arrobounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@-o-keyframes arrobounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

@keyframes arrobounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}


/* ============= Transitions code ============= */


/* =============== Media query code ============= */


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 03 - RESET STYLES */


/*-------------------------------------------------------------------------------------------------------------------------------*/

html {
    scroll-behavior: smooth;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

body * {
    -webkit-text-size-adjust: none;
}

.clear {
    clear: both;
    overflow: hidden;
    height: 0px;
    font-size: 0px;
    display: block;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input,
textarea,
select {
    font-family: Montserrat-Light;
    font-weight: 400;
    -webkit-appearance: none;
    border-radius: 0;
}

a,
a:link,
a:visited,
a:active,
a:hover {
    cursor: pointer;
    text-decoration: none;
    outline: none;
}

body {
    font-family: Montserrat-Light;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: #777777;
}

b,
strong {
    font-weight: 600;
}

br {
    display: block;
    margin-bottom: 10px;
}


/*-------------------------------------------------------------------------------------------------------------------------------*/


/* 04 - GLOBAL SETTINGS */


/*-------------------------------------------------------------------------------------------------------------------------------*/


/*typography*/

.titel-left {
    position: relative;
}

.titel-left span {
    color: #fbc011;
}

.titel-left:after {
    position: absolute;
    left: -25px;
    top: 0px;
    content: "";
    width: 5px;
    height: 100%;
}

.titel-top {
    position: relative;
}

.titel-top:after {
    position: absolute;
    left: 50%;
    top: -25px;
    content: "";
    width: 30px;
    margin-left: -15px;
    height: 5px;
    background: #fbc011;
}

.h1,
.h2,
.h4 {
    font-family: 'aqua', sans-serif;
    text-transform: uppercase;
    color: #222222;
}

.h1 {
    font-size: 56px;
    margin-bottom: 40px;
}

.h2 {
    font-size: 46px;
    line-height: 46px;
    margin-bottom: 40px;
    color: #717171;
    border-bottom: 5px solid #ccc;
}

.h3 {
    font-size: 30px;
    font-weight: normal;
    text-transform: uppercase;
    color: #222222;
}

.h4 {
    font-size: 20px;
    margin-bottom: 20px;
}

.h5 {
    font-size: 16px;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 20px;
}

.small {
    font-size: 14px;
    line-height: 24px;
}

.normall {
    font-size: 16px;
    line-height: 28px;
}

.big {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 70px;
}

article {
    position: relative;
    float: left;
    width: 100%;
}

article p {
    color: #777777;
    padding-bottom: 40px;
}

ul li {
    color: #777777;
    position: relative;
    list-style: none;
}

.white {
    color: #fff;
}

.black {
    color: #222222;
}


/*background*/

.bg {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-position: 50% 0px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    position: fixed;
    background-attachment: scroll;
}

.bg img {
    position: absolute;
}

.bg video {
    position: absolute;
}

.bg-span {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.57);
}

.bg-span.light {
    background: rgba(0, 0, 0, 0.35);
}

.clip {
    clip: rect(auto, auto, auto, auto);
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0px;
}


/* Global css */

.wrapper {
    align-items: stretch;
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 0;
    position: relative;
    /* overflow: hidden; */
}

.chali-com {
    width: 100%;
    float: left;
    position: relative;
}

.padd100 {
    padding: 80px 0px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .padd100 {
        padding: 60px 0px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .padd100 {
        padding: 60px 0px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .padd100 {
        padding: 60px 0px;
    }
}

.padd70 {
    padding: 70px 0px;
}


/*------------------------------------------------------------------------------*/


/* Input type, Placeholder and dropdown Design */


/*------------------------------------------------------------------------------*/

.form-group {
    /*------------------------------------*/
    /* dropdown Design */
    /*------------------------------------*/
}

.form-group h5 {
    color: #000;
    margin-bottom: 10px;
}

.form-group h5 span {
    color: #dc3545;
    font-size: 8px;
}

.form-group label {
    color: #515974;
    font-size: 13px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 5px;
}

.form-group .form-control {
    height: 40px;
    border: 1px solid #ddd;
    padding: 0px 10px;
    margin-bottom: 10px;
    box-shadow: none;
    border-radius: 0px;
    background-color: transparent;
    font-size: 15px;
    color: #445461;
    font-weight: 400;
    margin-bottom: 0px;
}

.form-group .form-control:hover,
.form-group .form-control:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid #9a9a9a;
    background-color: transparent;
}

.form-group .form-control::-webkit-input-placeholder {
    color: #999;
    font-family: 'Montserrat-Regular';
}

.form-group .form-control::-webkit-input-placeholder:hover {
    color: #58585f;
}

.form-group .form-control::-webkit-input-placeholder:focus {
    color: #58585f;
}

.form-group .form-control:-moz-placeholder {
    color: #999;
    font-family: 'Montserrat-Regular';
}

.form-group .form-control:-moz-placeholder:hover {
    color: #58585f;
}

.form-group .form-control:-moz-placeholder:focus {
    color: #58585f;
}

.form-group .form-control::-moz-placeholder {
    color: #999;
    font-family: 'Montserrat-Regular';
}

.form-group .form-control::-moz-placeholder:hover {
    color: #58585f;
}

.form-group .form-control::-moz-placeholder:focus {
    color: #58585f;
}

.form-group .form-control:-ms-input-placeholder {
    color: #999;
    font-family: 'Montserrat-Regular';
}

.form-group .form-control:-ms-input-placeholder:hover {
    color: #58585f;
}

.form-group .form-control:-ms-input-placeholder:focus {
    color: #58585f;
}

.form-group textarea {
    height: 80px !important;
    resize: none;
}

.form-group .formg_input {
    position: relative;
}

.form-group .formg_input .form-control {
    border-radius: 4px;
    border: 1px solid #d5d7da;
    background-color: #fff;
    height: 50px;
    padding: 0px 10px 0px 40px;
}

.form-group .formg_input .form-control:hover,
.form-group .formg_input .form-control:focus {
    background-color: #fff;
}

.form-group .formg_input span {
    position: absolute;
    left: 2%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
}

.form-group .formg_input:hover .form-control {
    border: 1px solid #333;
}

.form-group .formg_input:hover span {
    color: #333;
}

.form-group .formg_input.text-input span {
    top: 2%;
    transform: translateY(0%);
}

.form-group .bootstrap-select {
    margin-bottom: 0px;
    border: none;
    padding: 0px 0px;
}

.form-group .bootstrap-select:hover {
    border: none;
}

.form-group .bootstrap-select button {
    height: 40px;
    margin-bottom: 10px;
    box-shadow: none;
    border-radius: 0px !important;
    background-color: transparent;
    font-size: 15px;
    font-weight: 400;
    outline: none;
    padding: 7px 10px;
    margin-bottom: 0px;
    text-indent: 0px;
    border-radius: 4px;
    border: 1px solid #ddd !important;
}

.form-group .bootstrap-select button span {
    color: inherit;
}

.form-group .bootstrap-select button:hover,
.form-group .bootstrap-select button:focus {
    outline: none !important;
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: transparent !important;
    color: #5f5f5f !important;
    border: 1px solid #5f5f5f !important;
    border-radius: 0px;
    font-size: 15px;
}

.form-group .bootstrap-select button:hover span,
.form-group .bootstrap-select button:focus span {
    border: none;
}

.form-group .bootstrap-select button:after {
    margin-left: -10px;
    position: absolute;
    top: 50%;
}

.form-group .bootstrap-select.show .dropdown-toggle,
.form-group .bootstrap-select.show .dropdown-toggle:active,
.form-group .bootstrap-select.show .dropdown-toggle.active {
    box-shadow: none;
    background-color: transparent !important;
    border: 1px solid #5f5f5f !important;
}

.form-group .bootstrap-select .dropdown-menu ul li {
    padding: 0px;
    border: none !important;
    box-shadow: none !important;
    outline: none;
    margin-bottom: 0px;
}

.form-group .bootstrap-select .dropdown-menu ul li a {
    border: none;
    padding: 7px 10px;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
    outline: none;
}

.form-group .bootstrap-select .dropdown-menu ul li:selected {
    background-color: #6c8020 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
}

.form-group .bootstrap-select .dropdown-menu ul li:selected a {
    background-color: #6c8020 !important;
    color: #fff !important;
    border: none !important;
    box-shadow: none !important;
    outline: none;
}

.form-group .bootstrap-select .dropdown-menu ul li:hover a,
.form-group .bootstrap-select .dropdown-menu ul li:focus a {
    background-color: #6c8020;
    color: #fff;
    border: none !important;
    box-shadow: none !important;
    outline: none;
}

.form-group .bootstrap-select .dropdown-menu ul li:hover a span,
.form-group .bootstrap-select .dropdown-menu ul li:focus a span {
    border: none;
}

header {
    position: absolute;
    width: 100%;
    top: 0%;
    left: 0;
    right: 0;
    z-index: 999;
    transition: all 0.5s;
}

header .navbar {
    margin: 0px auto;
}

header .navbar .navbar-brand img {
    max-width: 100%;
    display: block;
}

@media (min-width: 320px) and (max-width: 767px) {
    header .navbar .navbar-brand img {
        width: 70px;
    }
}

header .navbar .navbar-toggler {
    outline: none;
    box-shadow: none;
    border: none;
    padding: 0px;
    margin-right: 15px;
}

header .navbar .navbar-toggler span {
    color: #fff;
    display: inline-block;
}

header .navbar .navbar-toggler:hover,
header .navbar .navbar-toggler:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar .navbar-toggler {
        display: none !important;
    }
}

header .navbar .navbar-collapse {
    justify-content: flex-end;
}

header .navbar .navbar-collapse .navbar-nav .nav-item {
    margin-left: 15px;
}

header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: white;
    display: inline-block;
    border-radius: 50px;
    padding: 15px 30px;
    font-size: 17px;
    font-weight: 400;
}

header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.active {
    color: #042C5C;
    font-family: 'Montserrat-Regular';
}

@media (min-width: 768px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.blueactive {
        background-color: #fff;
        padding: 15px 40px;
        margin-left: 30px;
        color: #042C5C !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }

    .img-item {
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.blueactive {
        display: block;
        padding: 15px 0px;
        color: #222222;
    }

    .img-item {
        padding-left: 0px;
        display: flex;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.blueactive {
        margin-left: 15px;
        padding: 15px 25px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        display: block;
        padding: 15px 0px;
        color: #222222;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        display: block;
        padding: 15px 10px;
        font-size: 14px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
        padding: 15px 20px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    header .navbar .navbar-collapse .navbar-nav .nav-item {
        margin-left: 0px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    header .navbar .navbar-collapse .navbar-nav {
        background-color: #fff;
        padding: 30px 15px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar .navbar-collapse .navbar-nav {
        flex-direction: row;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar .navbar-collapse {
        display: flex !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    header .navbar {
        flex-wrap: nowrap;
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    header .navbar {
        padding-left: 10px;
        padding-right: 10px;
    }
}

@media (min-width: 1200px) {
    header .navbar {
        max-width: 1140px;
    }
}

@media (min-width: 1400px) {
    header .navbar {
        max-width: 1320px;
    }
}

header.active {
    background-color: #fff;
    box-shadow: 0 0 30px 1px rgba(0, 0, 0, 0.15);
}

header.active .navbar .navbar-brand img {
    width: 70px;
}

@media (min-width: 320px) and (max-width: 767px) {
    header.active .navbar .navbar-brand img {
        width: 55px;
    }
}

header.active .navbar .navbar-toggler span {
    color: #222222;
}

header.active .navbar .navbar-collapse {
    justify-content: flex-end;
}

header.active .navbar .navbar-collapse .navbar-nav .nav-item .nav-link {
    color: #222222;
}

header.active .navbar .navbar-collapse .navbar-nav .nav-item .nav-link.blueactive {
    color: #042C5C;
}

.blue-anch a {
    background-color: #042C5C;
    padding: 15px 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 50px;
    text-align: center;
}

.blue-anch a.lightblue {
    background-color: #10A3EB;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-container .swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}


/*======== Home Landing Section ======*/

.landing-section {
    height: 100vh;
    width: 100vw;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background: linear-gradient(to bottom, #64DAF5 0%, #34B3E8 100%);
    /* background-image: url(../images/landing-bg.png);  */
}

.frame-landing-zone {
    width: 35vw;
    height: 35vh;
}

.landing-section .landing-content h2 {
    color: #fff;
    font-weight: 600;
    font-family: 'Montserrat-Bold';
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .landing-content h2 {
        font-size: 24px;
        line-height: 32px;
    }
}

.landing-section .landing-content p {
    color: #fff;
    font-family: 'Montserrat-Light';
    font-size: 18px;
    line-height: 24px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .landing-content p {
        font-size: 15px;
        line-height: 21px;
    }
}

.landing-section .landing-content .store_icons a {
    display: inline-block;
    position: relative;
    padding: 15px 25px 15px 45px;
    text-align: center;
    color: #fff;
    background-color: #042C5C;
    font-size: 13px;
    border: 1px solid #042C5C;
    border-radius: 50px;
    margin-right: 10px;
}

.landing-section .landing-content .store_icons a i {
    position: absolute;
    top: 50%;
    margin-left: 10px;
    left: 6%;
    transform: translateY(-50%);
    font-size: 22px;
}

@media (min-width: 992px) and (max-width: 1198px) {
    .landing-section .landing-content .store_icons a i {
        font-size: 20px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .landing-content .store_icons a {
        display: block;
        font-size: 16px;
        margin-bottom: 15px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .landing-section .landing-content .store_icons a {
        font-size: 12px;
        padding: 15px 15px 15px 38px;
    }
}

.landing-section .landing-image img {
    max-width: 100%;
    margin: 0px auto;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .landing-image img {
        width: 75%;
        margin: unset;
    }
}

.landing-section .social_icons {
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 3%;
    bottom: 20%;
    z-index: 1;
}

.landing-section .social_icons a {
    display: block;
    padding: 10px;
}

.landing-section .social_icons a img {
    max-width: 100%;
    margin: 0px auto;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .social_icons {
        bottom: 0%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing-section .social_icons {
        bottom: 2%;
    }
}

.landing-section .bottom_img {
    position: absolute;
    left: 0%;
    right: 0%;
    bottom: -6%;
}

.landing-section .bottom_img img {
    max-width: 100%;
    display: block;
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section .bottom_img {
        bottom: -10%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing-section .bottom_img {
        bottom: -2%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .landing-section {
        padding-top: 110px;
        height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .landing-section {
        background-position: 75% 50%;
    }
}

@media (min-width: 1400px) {
    .landing-section {
        background-size: 103%;
    }
}

.section-14 {
    /* height: 100%;
  width: 100%; */
    /* padding-top: 100px; */
}

.section-14 .bottomroad_img img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}


/*======== Section 2 ======*/

.section-2 {
    padding-top: 100px;
}

.section-2 .s2-heading {
    text-align: center;
    min-height: 200px;
}

.section-2 .s2-heading h2 {
    font-weight: 600;
    font-family: 'Montserrat-SemiBold';
    font-size: 40px;
    line-height: 45px;
    color: #042C5C;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-2 .s2-heading h2 {
        font-size: 26px;
        line-height: 36px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-2 .s2-heading h2 {
        font-size: 24px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-2 .s2-heading h2 {
        font-size: 32px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-2 .s2-heading {
        min-height: 150px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-2 .s2-heading {
        min-height: 100px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-2 .s2-heading {
        min-height: 150px;
    }
}

.section-2 .bottomroad_img_base {
    max-width: 100%;
    width: 100%;
    display: block;
}

.section-2 .bottomroad_img_car {
    position: absolute;
    width: 15%;
    height: auto;
    bottom: 5%;
    left: 0;
    animation: final__moveRight 7s linear infinite;
}

.section-2 .bottomroad_img_truck {
    position: absolute;
    width: 15%;
    height: auto;
    bottom: 17%;
    right: 0;
    animation: final__moveLeft 7s linear infinite;
}

.section-2 .bottomroad_img_scoot {
    position: absolute;
    width: 7%;
    height: auto;
    bottom: 4%;
    left: 30%;
    animation: final__moveScoot 5s linear infinite;
}

.section-2 .bottomroad_img_velo {
    position: absolute;
    width: 5%;
    height: auto;
    bottom: 30%;
    left: 0;
    animation: final__moveRight 7s linear infinite;
}

.section-2 .bottomroad_img_old {
    position: absolute;
    height: 10%;
    width: auto;
    bottom: 45%;
    left: 0%;
    animation: final__moveRight 20s linear infinite;
}

.section-2 .bottomroad_img_family {
    position: absolute;
    width: 7%;
    height: auto;
    bottom: 35%;
    right: 0;
    animation: final__moveLeft 15s linear infinite;
}

.section-2 .bottomroad_img_young {
    position: absolute;
    height: 10%;
    width: auto;
    bottom: 40%;
    right: 0;
    animation: final__moveLeft 12s linear infinite;
}

@keyframes final__moveRight {
    0% {
        left: -20%;
    }

    100% {
        left: 100%;
    }
}

@keyframes final__moveScoot {
    0% {
        left: -20%;
    }

    100% {
        left: 100%;
    }
}

@keyframes final__moveLeft {
    0% {
        right: -20%;
    }

    100% {
        right: 100%;
    }
}


/*======== Section 3 ======*/

.section-3 {
    padding: 100px 0px 0px;
}

.section-3 .s3-heading h2 {
    color: #051F61;
    font-size: 36px;
    margin-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-3 .s3-heading h2 {
        font-size: 28px;
    }
}

.section-3 .s3-heading p {
    color: #051F61;
    font-size: 21px;
    line-height: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-3 .s3-heading p {
        font-size: 16px;
        line-height: 22px;
    }
}

.section-3 .card-box {
    padding: 20px;
}

.section-3 .card-box .card-img {
    text-align: center;
    margin-bottom: 20px;
    min-height: 257px;
}

.section-3 .card-box .card-img img {
    max-width: 100%;
    margin: 0px auto;
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-3 .card-box .card-img img {
        width: 175px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-3 .card-box .card-img {
        min-height: auto;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-3 .card-box .card-img {
        min-height: 195px;
    }
}

.section-3 .card-box .card-body {
    text-align: center;
}

.section-3 .card-box .card-body h3 {
    color: #051F61;
    font-size: 22px;
    margin-bottom: 20px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-3 .card-box .card-body h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-3 .card-box .card-body h3 {
        font-size: 18px;
        line-height: 26px;
    }
}

.section-3 .card-box .card-body p {
    color: #051F61;
    font-size: 18px;
    line-height: 30px;
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-3 .card-box .card-body p {
        font-size: 14px;
        line-height: 21px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-3 .card-box .card-body p {
        font-size: 14px;
        line-height: 21px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-3 .card-box {
        padding: 0px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-3 {
        padding: 70px 0px 50px;
    }
}


/*======== Section 13 ======*/

.section-13 {
    padding: 100px 100px 100px;
}

.image-grid {
    --gap: 8px;
    --num-cols: 2;
    --row-height: 300px;
    box-sizing: border-box;
    padding: var(--gap);
    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}

.image-grid>img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.image-grid-col-2 {
    grid-column: span 1;
}

.image-grid-row-2 {
    grid-row: span 2;
}

.image-grid-two {
    --gap: 16px;
    --num-cols: 4;
    --row-height: 300px;
    box-sizing: border-box;
    padding: var(--gap);
    display: grid;
    grid-template-columns: repeat(var(--num-cols), 1fr);
    grid-auto-rows: var(--row-height);
    gap: var(--gap);
}

.image-grid-two>img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.image-grid-two-col-2 {
    grid-column: span 2;
}

.image-grid-two-row-2 {
    grid-row: span 2;
}


/* Anything udner 1024px */

@media screen and (max-width: 1024px) {
    .image-grid {
        --num-cols: 2;
        --row-height: 200px;
    }
}


/*======== Section 4 ======*/

.section-4 {
    padding: 100px 0px 100px;
}

.section-4 .s4-heading h2 {
    color: #051F61;
    font-size: 36px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .s4-heading h2 {
        font-size: 28px;
    }
}

.section-4 .nos-card {
    padding: 25px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.section-4 .nos-card h3 {
    color: #478cd8;
    font-size: 22px;
    margin-bottom: 20px;
    /* text-transform: uppercase; */
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .nos-card h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.section-4 .nos-card span {
    display: block;
    color: #478cd8;
    font-family: 'Montserrat-Bold';
    margin-bottom: 20px;
    font-size: 22px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .nos-card span {
        font-size: 18px;
        margin-bottom: 10px;
    }
}

.section-4 .nos-card .nos-icon {
    display: block;
    font-size: 100px;
    color: #d8d8d8;
    margin-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .nos-card .nos-icon {
        font-size: 60px;
        margin-bottom: 10px;
    }
}

.nos-image {
    display: block;
    max-width: 100%;
    margin: 0px auto;
}

.section-4 .nos-card p {
    color: #478cd8;
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .nos-card p {
        font-size: 14px;
        line-height: 21px;
    }
}


/* .section-4 .nos-card:hover {
    background-color: #64DAF5; }
    .section-4 .nos-card:hover h3 {
      color: #ffffff; }
    .section-4 .nos-card:hover span {
      color: #ffffff; }
    .section-4 .nos-card:hover .nos-icon {
      color: #ffffff; }
    .section-4 .nos-card:hover p {
      color: #ffffff; } */

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 .nos-card {
        padding: 15px;
        margin-bottom: 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-4 .nos-card {
        margin-bottom: 20px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-4 {
        padding: 15px 0px 70px;
    }
}


/*======== Section 20 ======*/

.section-20 {
    flex-wrap: wrap;
    padding-bottom: 150px;
}

.section-20 img {
    object-fit: fill;
    max-width: 100%;
    width: 100%;
}


/*======== Section 5 ======*/

.section-5 {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 150px;
}

.section-5 .oval-div {
    position: absolute;
    left: -48%;
    top: 0%;
    bottom: 0%;
}

.section-5 .oval-div img {
    max-width: 100%;
    display: block;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 .oval-div {
        left: -78%;
    }
}

.section-5 .sect-5-left {
    flex: 0 0 auto;
    width: 35%;
    position: relative;
    z-index: 2;
    padding: 70px 50px;
}

.section-5 .sect-5-left h2 {
    margin-bottom: 20px;
    color: #042C5C;
    font-size: 42px;
    line-height: 52px;
    font-family: 'Montserrat-Bold';
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 .sect-5-left h2 {
        font-size: 34px;
        line-height: 44px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-5 .sect-5-left h2 {
        font-size: 34px;
        line-height: 44px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-5 .sect-5-left h2 {
        font-size: 34px;
        line-height: 44px;
    }
}

.section-5 .sect-5-left p {
    color: #1F1F1F;
    font-size: 16px;
    line-height: 24px;
}

.section-5 .sect-5-left .blue-anch {
    display: flex;
}

.section-5 .sect-5-left .orangebot-img {
    position: absolute;
    right: -23%;
    bottom: 0%;
}

.section-5 .sect-5-left .orangebot-img img {
    max-width: 100%;
    display: block;
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-5 .sect-5-left .orangebot-img img {
        width: 55%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 .sect-5-left .orangebot-img {
        position: relative;
        right: -7%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-5 .sect-5-left .orangebot-img {
        position: relative;
        margin-top: 30px;
        right: 0px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-5 .sect-5-left .orangebot-img {
        right: -99%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 .sect-5-left {
        width: 100%;
        padding: 70px 15px 30px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-5 .sect-5-left {
        width: 40%;
        padding: 30px 20px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-5 .sect-5-left {
        padding: 70px 30px;
    }
}

.section-5 .sect-5-right {
    flex: 0 0 auto;
    width: 65%;
    position: relative;
    z-index: 1;
}

.section-5 .sect-5-right img {
    max-width: 100%;
    display: block;
    width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 .sect-5-right {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-5 .sect-5-right {
        width: 60%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-5 {
        padding-bottom: 70px;
    }
}

@media (min-width: 992px) and (max-width: 1198px) {
    .section-5 {
        padding-bottom: 100px;
    }
}


/*======== Shop page css ======*/

.top-cart {
    height: 10%;
    width: 100%;
}

.list-cart {
    height: 70%;
    width: 100%;
    overflow: auto;
}

.test-cart {
    position: absolute;
}

@media (max-width: 1125px) {
    #testIDmerde {
        display: none
    }

    #testIDmerde2 {
        width: 100%;
    }

    .shopland-img {
        display: none
    }
}

@media (max-width: 1125px) {

    .shopland-img img {
        left: 0%;
    }
}

.item-cart-container {
    position: relative;
    width: 90%;
    height: 200px;
    margin-left: 5%;
    border: 1px solid #040404;
    margin-bottom: 15px;
}

.item-cart-image {
    max-width: 190px !important;
}

.item-cart-delete {
    position: absolute;
    right: 0;
    top: 0;
}


@media (max-width: 999px) {
    .item-cart-name h1 {
        margin-bottom: 0;
        font-size: 24px;
    }

    .item-cart-price h2 {

        margin-bottom: 0;
        font-size: 24px;
    }

    .item-cart-container {
        height: auto;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 8px;
    }

    .item-cart-image {
        display: none !important;
    }

    .item-cart-delete {
        position: absolute;
        right: 0;
    }

}

@media (max-width: 500px) {
    .item-cart-delete {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .item-cart-name h1 {
        margin-bottom: 0;
        font-size: 20px;
    }

    .item-cart-price h2 {

        margin-bottom: 0;
        font-size: 20px;
    }
}

@media (max-width: 380px) {
    .item-cart-delete {
        position: absolute;
        right: 0;
        bottom: 0;
    }
    .item-cart-name h1 {
        margin-bottom: 0;
        font-size: 16px;
    }

    .item-cart-price h2 {

        margin-bottom: 0;
        font-size: 16px;
    }
}
.item-cart-image img {
    margin-top: 5px;
    height: 190px;
}


.price-cart .total {
    position: relative;
    left: 5% !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.price-cart .price {
    right: 5% !important;
    position: relative;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.price-cart {
    height: 10%;
    width: 100%;
}

.btn-cart-pay {
    position: relative;
    right: 5% !important;
    top: 50% !important;
    width: 15% !important;
    transform: translateY(-50%) !important;
    background: linear-gradient(#64DAF5, #34B3E8) !important;
    color: white !important;
}

@media (max-width: 1125px) {

    .btn-cart-pay {
        width: 40% !important;
    }
}

@media (max-width: 400px) {

    .btn-cart-pay {
        width: 100% !important;
    }
}

.center-cart {
    height: 80%;
    width: 100%;
}

.bottom-cart {
    height: 10%;
    width: 100%;
}

.panier {
    border-radius: 0rem !important;
    outline: solid 1px !important;
    outline-color: #042C5C !important;
    color: #042C5C !important;
}

.shoplanding-section {
    background: linear-gradient(to bottom, #64DAF5 0%, #34B3E8 100%);
}

.shoplanding-section .shopland-one {
    height: 60vh;
}

.shoplanding-section .shopland-one .shopland-img img {
    max-width: 100%;
    position: absolute;
    top: 70%;
    left: 30%;
    transform: translate(-50%, -50%);
    margin: 0px auto;
}

.shoplanding-section .shopland-one .shopland-content h4 {
    color: #fff;
    padding-top: 7%;
    font-size: 34px;
    margin-bottom: 20px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .shoplanding-section .shopland-one .shopland-content h4 {
        font-size: 28px;
    }
}


.shoplanding-section .shopland-one .shopland-content h2 {
    color: #fff;
    font-family: 'Montserrat-Bold';
    font-weight: 600;
    font-size: 49px;
    line-height: 55px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .shoplanding-section .shopland-one .shopland-content h2 {
        font-size: 35px;
        line-height: 45px;
    }
}

.shoplanding-section .shopland-one .shopland-content p {
    color: #fff;
    font-size: 23px;
    line-height: 30px;
}


@media (max-width: 319px) {
    .shoplanding-section .shopland-one .shopland-content h4 {
        color: #fff;
        padding-top: 60px;
        font-size: 30px;
        margin-bottom: 10px;
    }

    .shoplanding-section .shopland-one .shopland-content h2 {
        color: #fff;
        font-family: 'Montserrat-Bold';
        font-size: 20px;
        margin-bottom: 20px !important;
        line-height: 20px;
        /* display: none; */
    }

    .shoplanding-section .shopland-one .shopland-content p {
        color: #fff;
        font-size: 16px;
        line-height: 16px;
    }
}

@media (min-width: 1599px) {
    .shoplanding-section .shopland-one {
        height: 60vh;
    }
}

.shoplanding-section .shopland-two {
    min-height: 261px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .shoplanding-section .shopland-two {
        min-height: 100px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .shoplanding-section {
        padding-top: 40px;
    }
}

.productlist-section {
    padding-top: 3%;
    padding-bottom: 100px;
}

.productlist-section .product-box {
    border-radius: 14px 14px 10px 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.productlist-section .product-box a {
    display: block;
}

.productlist-section .product-box .product-img {
    text-align: center;
    border-radius: 14px 14px 0px 0px;
}

.productlist-section .product-box .product-img img {
    max-width: 100%;
    margin: 0px auto;
    width: 100%;
}

.productlist-section .product-box .product-title {
    text-align: center;
    background-color: #111111;
    padding: 20px;
    border-radius: 0px 0px 10px 10px;
}

.productlist-section .product-box .product-title h4 {
    color: #fff;
    font-size: 23px;
    font-family: 'Montserrat-Bold';
    font-weight: 600;
}

@media (min-width: 1599px) {
    .productlist-section {
        margin-top: 0;
    }
}


/*======== Section 12 ======*/

.section-12 {
    padding: 60px 0px 100px;
}

.section-12 .box-panel {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 31px;
    flex-wrap: wrap;
}

.section-12 .box-panel .box-body {
    flex: 0 0 auto;
    width: 50%;
}

.section-12 .box-panel .box-body h2 {
    color: #042C5C;
    font-size: 38px;
    line-height: 46px;
    font-family: 'Montserrat-Bold';
    margin-bottom: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-12 .box-panel .box-body h2 {
        font-size: 30px;
        line-height: 40px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-12 .box-panel .box-body h2 {
        font-size: 30px;
        line-height: 40px;
    }
}

.section-12 .box-panel .box-body p {
    color: #B7BBBF;
    font-size: 23px;
    line-height: 36px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-12 .box-panel .box-body p {
        font-size: 16px;
        line-height: 22px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .section-12 .box-panel .box-body p {
        font-size: 16px;
        line-height: 22px;
    }
}

.section-12 .box-panel .box-body .blue-anch {
    display: flex;
}

.section-12 .box-panel .box-body.box-left {
    border-radius: 31px;
}

.section-12 .box-panel .box-body.box-left img {
    max-width: 100%;
    border-radius: 31px;
}

.section-12 .box-panel .box-body.box-right {
    padding: 0px 40px;
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-12 .box-panel .box-body.box-right {
        padding: 40px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-12 .box-panel .box-body {
        width: 100%;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    .section-12 {
        padding-bottom: 40px;
    }
}

.subdiv {
    text-align: center;
    display: flex;
    justify-content: center;
}

.subdiv button {
    text-decoration: none;
    box-shadow: unset;
    border: none;
    display: block;
    text-align: center;
    color: #fff;
    background-color: #042C5C;
    padding: 15px 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50px;
}

.subdiv button:hover,
.subdiv button:focus {
    text-decoration: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: none;
}

.shop-details {
    text-align: center;
}

.shop-details p {
    margin-bottom: 15px;
    color: #222222;
    font-size: 17px;
    line-height: 22px;
}

.shop-details span {
    display: block;
    color: #478cd8;
    font-family: 'Montserrat-Bold';
    margin-bottom: 20px;
    font-size: 22px;
    margin-bottom: 15px;
}

.shop-details a {
    text-decoration: none;
    box-shadow: unset;
    border: none;
    display: block;
    text-align: center;
    color: #fff;
    background-color: #042C5C;
    padding: 15px 40px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 50px;
}

.shop-details a:hover,
.shop-details a:focus {
    text-decoration: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border: none;
}

.section-devni {
    min-height: 115px;
    /*background-position:center;background-repeat: no-repeat;background-size: cover;background-image: url(../images/blue-react-bg.png);*/
    background: linear-gradient(to bottom, #64daf5 0%, #34b3e8 100%);
}


/*======== footer ======*/

footer .footer-one {
    padding-top: 50px;
}

footer .footer-one .f-one-content h2 {
    color: #222222;
    font-size: 30px;
    line-height: 42px;
    font-family: 'Montserrat-Regular';
    margin-bottom: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-one .f-one-content h2 {
        font-size: 26px;
        line-height: 38px;
    }
}

footer .footer-one .f-one-content p {
    color: #222222;
    font-size: 17px;
    line-height: 22px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-one .f-one-content p {
        font-size: 14px;
        line-height: 21px;
    }
}

footer .footer-one .storelink h4 {
    font-size: 24px;
    color: #222222;
    margin-bottom: 30px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-one .storelink h4 {
        font-size: 20px;
    }
}

footer .footer-one .storelink a {
    display: inline-block;
    margin: 0px 15px;
}

footer .footer-one .storelink a img {
    max-width: 100%;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-one .storelink a img {
        height: 35px;
    }
}

footer .footer-one .fobot-img img {
    max-width: 100%;
    display: block;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-one .fobot-img {
        margin-top: 30px;
    }
}

footer .footer-two {
    padding: 60px 0px;
}

footer .footer-two .footerlogo img {
    max-width: 100%;
    margin: 0px auto;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two .footerlogo {
        margin-bottom: 30px;
    }
}

footer .footer-two .footerlink-div h5 {
    font-size: 17px;
    color: #222222;
    margin-bottom: 15px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two .footerlink-div h5 {
        margin-bottom: 10px;
    }
}

footer .footer-two .footerlink-div ul {
    display: block;
    list-style: none;
}

footer .footer-two .footerlink-div ul li {
    display: block;
    list-style: none;
    margin-bottom: 15px;
}

footer .footer-two .footerlink-div ul li a {
    display: inline-block;
    font-size: 15px;
    color: #222222;
    opacity: 0.7;
    padding: 5px 0px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two .footerlink-div ul li a {
        font-size: 13px;
        margin-right: 10px;
    }
}

footer .footer-two .footerlink-div ul li a:hover {
    opacity: 1;
}

footer .footer-two .footerlink-div ul li:last-child {
    margin-bottom: 0px;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two .footerlink-div ul li {
        display: inline-block;
        margin-bottom: 10px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two .footerlink-div {
        margin-bottom: 20px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-two {
        padding-bottom: 20px;
    }
}

footer .footer-three {
    padding: 30px 0px;
    border-top: 1px solid rgba(34, 34, 34, 0.18);
}

footer .footer-three .tcenter {
    text-align: center;
}

footer .footer-three .tcenter p {
    opacity: 0.7;
    color: #222222;
}

@media (min-width: 768px) and (max-width: 991px) {
    footer .footer-three .tcenter p {
        font-size: 10px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-three .tcenter {
        margin-bottom: 10px;
    }
}

footer .footer-three .des-dev-anch a {
    display: inline-block;
    text-decoration: underline;
    opacity: 0.7;
    color: #222222;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-three .des-dev-anch a {
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    footer .footer-three .des-dev-anch a {
        font-size: 10px;
    }
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-three .des-dev-anch {
        margin-bottom: 15px;
    }
}

footer .footer-three .socail-anch {
    display: flex;
    justify-content: flex-end;
}

footer .footer-three .socail-anch a {
    display: inline-block;
    color: #222222;
    font-size: 16px;
    margin-left: 10px;
    margin-left: 20px;
    opacity: 0.7;
}

@media (min-width: 320px) and (max-width: 767px) {
    footer .footer-three .socail-anch {
        justify-content: center;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    footer .footer-three .socail-anch {
        justify-content: center;
    }
}


/*======= Media Query ====== */

@media (min-width: 320px) {
    .sm-device {
        display: block;
    }

    .lg-device {
        display: none;
    }
}

@media (min-width: 1599px) {
    .sm-device {
        display: none;
    }

    .lg-device {
        display: block;
    }
}

html,
body,
#app,
#root,
.wrapper,
#app>div {
    height: 100%;
    width: 100%;
}

.product-img {
    position: relative;
    border: 1px solid black;
    width: 100%;
    height: 100%;
}

.product-img img {
    max-width: 100%;
    max-height: 100%;
}

.overlay {
    border-radius: 14px 14px 0 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
}

.product-img:hover .overlay {
    display: block;
}


/* 
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
} */